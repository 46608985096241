import React from "react";
import Link from "next/link";
import {Header} from "semantic-ui-react";
import Tools from "../../tools/Tools";
import "./TagLink.less";

const TagLink = ({tag, region, profession}) => {
    if(!tag || !region){
        return null;
    }

    let link = `/rabota/${Tools.transliterate(region.title || region.name)}/${Tools.transliterate(tag.title)}`;
    let name = `${tag.title.toLowerCase()}`;
    if(profession && profession.title){
        link = `${link}/${Tools.transliterate(profession.title)}`;
        name = `${profession.title} ${name}`;
    }

    return <Link href={"/rabota/[...slug]"} as={link}>
        <a className="tag-link">
            <Header as={"h4"} className="primary light">
                {name}
            </Header>
        </a>
    </Link>
}

export default TagLink;