import React from "react";
import {Button} from "semantic-ui-react";
import ym from 'react-yandex-metrika';

const VacancyRespondButton = ({text = "Откликнуться"}) => {

    const respond = () => {
        ym('reachGoal', 'VACANCY_RESPONDING');
    };

    return (
        <Button as={"a"}
                target="_blank"
                className={"button-orange"}
                onClick={respond}
                rel={"nofollow"}
                href="https://forms.gle/rrkGmVXkRX31eS1o8">
            {text}
        </Button>
    );
};

export default VacancyRespondButton;