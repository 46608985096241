import React, {useState} from "react";
import {connect, useDispatch, useStore} from "react-redux";
import {moreVacancies, nextPage} from "../../../actions/vacancyActions";
import {Button} from "semantic-ui-react";

const mapStateToProps = state => (
    {
        total: state.vacancies.total,
        parameters: state.vacancies.parameters,
    }
);

const getNextPageUrl = (url, page) => {
    if(url){
        const path = url.split('?')[0];
        if(path){
            return `${path}?page=${page + 1}`;
        }
    }
    return url;
};

const Pagination = ({total, parameters, url}) => {
    const store = useStore();
    const dispatch = useDispatch();

    const hasDocuments = total > 0 && total > (parameters.page * parameters.pageSize);

    const [visible, setVisible] = useState(hasDocuments);

    if(visible && hasDocuments){
        return (
            <div>
                <Button as="a" href={getNextPageUrl(url, parameters.page)} onClick={async (e) => {
                    e.preventDefault();
                    setVisible(false);
                    await dispatch(nextPage());
                    await dispatch(moreVacancies(store));
                    setVisible(true);
                }} className="fluid button-orange" size="large">
                    Показать еще
                </Button>
            </div>
        );
    }
    return null;
}

export default connect(mapStateToProps)(Pagination)

