import React from "react";
import PropTypes from "prop-types";
import "./VacancyItem.less";
import VacancyTitle from "./Components/VacancyTitle";
import VacancyDescription from "./Components/VacancyDescription";
import VacancyAddress from "./Components/VacancyAddress";
import VacancySalary from "./Components/VacancySalary";
import VacancyTags from "./Components/VacancyTags";
import VacancyCompany from "./Components/VacancyCompany";
import VacancyMetro from "./Components/VacancyMetro";
import VacancyActions from "./Components/VacancyActions";
import VacancyExtra from "./Components/VacancyExtra";
import Tools from "../../../tools/Tools";

export const getCategories = (vacancy) => {
    if(!vacancy){
        return null;
    }
    if(!Tools.isEmpty(vacancy.professions)){
        const list = vacancy.professions.reduce((result, profession) => {
            if(!Tools.isEmpty(profession.categories)){
                result = [...result, ...profession.categories];
            }
            return result;
        }, []);
        return Tools.distinct(list, c => c.id);
    }
    return [];
}

export const getProfessions = (vacancy) => {
    if (!vacancy) {
        return null;
    }
    return vacancy.professions || [];
};

export const getRegion = (vacancy) => {
    if(!vacancy){
        return null;
    }
    return vacancy.address
        ? (vacancy.address.city || vacancy.address.region)
        : null;
}

const VacancyItem = ({vacancy, link = true, collapsed = true, titleAs = "h3"}) => {

    const region = getRegion(vacancy);
    const profession = getProfessions(vacancy)[0];
    let category = getCategories(vacancy)[0];

    return (
        <div className={"vacancy"}>
            <VacancyTitle id={vacancy.id}
                          professions={vacancy.professions}
                          name={vacancy.title}
                          as={titleAs}
                          link={link} />

            <VacancySalary salary={vacancy.salary} />

            <VacancyDescription
                description={vacancy.description}
                link={link}
                id={vacancy.id}
                collapsed={collapsed} />

            <VacancyCompany company={vacancy.company} />
            <VacancyTags tags={vacancy.tags} region={region} profession={profession} />
            <VacancyAddress address={vacancy.address} />
            <VacancyMetro metro={vacancy.metroStations} region={region} profession={profession} />
            <VacancyActions id={vacancy.id} isFavorite={false} contact={vacancy.contact} status={vacancy.state} />
            <VacancyExtra id={vacancy.id} date={vacancy.updatedDate} category={category} region={region} />
        </div>);
};

VacancyItem.propTypes = {
    vacancy: PropTypes.object.isRequired,
    link: PropTypes.bool,
    collapsed: PropTypes.bool
};

export default VacancyItem;