import React from "react";
import Tools from "../../../../tools/Tools";
import Link from "next/link";

const getLink = (region, tag, profession) => {
    let link = `/rabota/${Tools.transliterate(region.name)}/${Tools.transliterate(tag.name)}`;
    if(profession){
        link += `/${Tools.transliterate(profession.name)}`;
    }
    return link;
};

const VacancyTags = ({tags, region, profession}) => {
    if(Tools.isEmpty(tags)){
        return null;
    }

    if(!region){
        console.warn("Region or profession was undefined");
        return null;
    }

    const items = tags
        .map(tag => <Link key={tag.id} href={"/rabota/[...slug]"} as={getLink(region, tag, profession)}>
            <a className="vacancy-tag" style={{backgroundColor: Tools.palette(tag.id)}}>
                <span>{tag.name}</span>
            </a>
        </Link>);

    return (
        <div className="vacancy-tags-list">
            {items}
        </div>
    );
}

export default VacancyTags;