import React from "react";
import Tools from "../../tools/Tools";
import ProfessionLink from "./ProfessionLink";
import "./ProfessionList.less";
import SwiperCore, {Navigation, Pagination} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

const ProfessionList = ({professions, region, count = 8, expandedCount = 48, metro = null, tag = null, all = false}) => {
    if(Tools.isEmpty(professions) || !region){
        return null;
    }
    let filteredProfessions = professions;
    if(!all){
        filteredProfessions = professions.filter(p => p.vacanciesCount > 0).slice(0, expandedCount);
    }

    let content = null;
    if((filteredProfessions.length / count) > 1){
        let slides = [];
        for(let i = 0; i < filteredProfessions.length; i+=count){
            slides.push(
                <SwiperSlide key={i}>
                    <div className="profession-container">
                        <div className="profession-list">
                            {filteredProfessions.slice(i, i+count).map(profession => {
                                return (
                                    <div key={profession.id} className="profession-item">
                                        <ProfessionLink profession={profession}
                                                        region={region}
                                                        metro={metro}
                                                        tag={tag} />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </SwiperSlide>);
        }
        if(Tools.isEmpty(slides)) {
            return null;
        }
        SwiperCore.use([Navigation, Pagination]);
        content = <Swiper
            spaceBetween={0}
            slidesPerView={1.1}
            autoHeight={true}
            pagination={{ clickable: true, dynamicBullets: true}}>
            {slides}
        </Swiper>
    }
    else{
        content =  <div className="profession-container">
            <div className="profession-list">
                {filteredProfessions.map(profession => {
                    return (
                        <div key={profession.id} className="profession-item">
                            <ProfessionLink profession={profession}
                                            region={region}
                                            metro={metro}
                                            tag={tag} />
                        </div>
                    );
                })}
            </div>
        </div>
    }
    return content;
};

export default ProfessionList;

