import React from "react";
import Tools from "../../tools/Tools";
import Link from "next/link";

const ProfessionLink = ({profession, region, metro, tag}) => {
    if(!profession || !region){
        return null;
    }
    const name = Tools.toUpperCaseFirstChar(profession.name);
    let link = `/rabota/${Tools.transliterate(region.title || region.name)}`;
    if(metro){
        link = `${link}/metro/${Tools.transliterate(metro.title)}`;
    }
    else if(tag){
        link = `${link}/${Tools.transliterate(tag.title)}`;
    }
    link = `${link}/${Tools.transliterate(name)}`;
    const count = ` ${profession.vacanciesCount}`

    return (
        <Link href="/rabota/[...slug]" as={link}>
            <a className="profession-item-link">
                {name}
                <span className="profession-item-count">
                       {count}
                </span>
            </a>
        </Link>
    );
};

export default ProfessionLink;