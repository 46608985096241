import React from "react";
import Tools from "../../tools/Tools";
import {Header} from "semantic-ui-react";
import TagLink from "../Tag/TagLink";
import "./TopTags.less";

const TopTags = ({tags, region, showTitle, profession}) => {
    if(Tools.isEmpty(tags) || !region){
        return null;
    }

    return (
        <div className="top-tags">
            {showTitle === true ? <Header as={"h2"} className="primary light">ПОПУЛЯРНЫЕ ТЕГИ</Header> : null}
            <div className="top-tags-list">
                {tags.map(tag => <TagLink key={tag.id} region={region} tag={tag} profession={profession} />)}
            </div>
        </div>
    );
};

export default TopTags;