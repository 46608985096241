import React from "react";
import {Grid, Icon} from "semantic-ui-react";
import Tools from "../../../../tools/Tools";
import CategoryLink from "../../../Category/CategoryLink";

const VacancyExtra = ({id, date, category, region}) => {
    return (
        <div className="vacancy-extra">
            <Grid>
                <Grid.Column computer={6} mobile={6}>
                    <span className="meta vacancy-date">{Tools.toDate(date)}</span>
                </Grid.Column>
                <Grid.Column computer={10} mobile={10}>
                    <CategoryLink className={"meta category-link"} category={category} region={region} />
                </Grid.Column>
            </Grid>
        </div>
    );
};

export default VacancyExtra;