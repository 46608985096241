import React, {useState} from "react";
import "./SearchAround.less";
import {Button, Header, Modal} from "semantic-ui-react";
import {connect, useDispatch, useStore} from "react-redux";
import {searchVacancies, setSearchParameters} from "../../../actions/vacancyActions";
import Tools from "../../../tools/Tools";
import AvataromMetrika from "../../../tools/AvataromMetrika";

const DEFAULT_DISTANCE = 10;

const mapStateToProps = state => (
    {
        parameters: state.vacancies.parameters,
    }
);

const SearchAround = ({parameters}) => {
    if(!parameters){
        return null;
    }

    const [open, setOpen] = useState(false);

    const isAround = parameters.around;
    const store = useStore();
    const dispatch = useDispatch();

    console.log("AROUND: ", isAround);

    const apply = async (store) => {
        await dispatch(searchVacancies(store));
        Tools.scrollTop();
    };

    const applyAround =  async (coords) => {
        await dispatch(setSearchParameters({
            ...parameters,
            cityId: null,
            lat: coords.latitude,
            lon: coords.longitude,
            distance: DEFAULT_DISTANCE,
            around: true
        }));
        await apply(store);
    };

    // TODO Сделать определение региона по координатам
    const cancelAround = async () => {
        await dispatch(setSearchParameters({
            ...parameters,
            cityId: 19,
            lat: null,
            lon: null,
            distance: null,
            around: false
        }));
        await apply(store);
    };

    const showAround = () => {
        try{
            AvataromMetrika.reachGoal('SHOW_AROUND');

            window.navigator.geolocation.getCurrentPosition(async (position) => {
                if(position && position.coords){
                    if(isAround){
                        await cancelAround();
                    }
                    else{
                        AvataromMetrika.reachGoal('SHARING_LOCATION');
                        await applyAround(position.coords);
                    }
                }
            }, () => {});
        }
        catch (e) {
            console.error(e);
        }
    };

    const showAroundMobile = async () => {
        if(!isAround){
            setOpen(true);
        }
        await cancelAround();
    };

    const text = "Разрешите доступ к своему местоположению и смотрите вакансии рядом! Это очень удобно.";

    const distanceControl = isAround === true
        ? <div id="around-distance">
            <div className="around-distance-content">
                <Header as={"h5"} className="primary light">
                    Вакансии ближе {parameters.distance} км от вас
                </Header>
            </div>
            <Button size={"small"} icon={"close"} className="around-cancel-button"
             onClick={cancelAround}>
            </Button>
        </div>
        : null;

    return <div className="search-around">
        <Header as={"h3"} className="primary light">
            Показать вакансии рядом
        </Header>
        <Button className={`button-blue around-button ${isAround ? "around-active" : ""}`}
                icon={"map marker alternate"}
                onClick={showAround}>
        </Button>
        <Header as={"h5"} className="primary light">
            {text}
        </Header>
        <Button id={"show-around-mobile"} className={`button-blue around-button ${isAround ? "around-active" : ""}`}
                icon={"map marker alternate"}
                onClick={showAroundMobile}>
        </Button>
        <Modal size="tiny" open={open} onClose={() => setOpen(false)}>
            <Modal.Header>Вакансии рядом с Вами</Modal.Header>
            <Modal.Content>
                <p>
                    {text}
                </p>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    positive
                    icon='checkmark'
                    labelPosition='right'
                    content='Хорошо!'
                    onClick={() => {
                        setOpen(false);
                        showAround();
                    }}
                />
            </Modal.Actions>
        </Modal>
        {distanceControl}
    </div>
};
export default connect(mapStateToProps)(SearchAround);