import React from "react";
import Link from "next/link";
import Tools from "../../tools/Tools";

const CategoryLink = ({category, region, showCount = true, ...rest}) => {
    if(!category || !region){
        return null;
    }

    const link = `/rabota/${Tools.transliterate(region.title || region.name)}/category/${Tools.transliterate(category.title)}`;

    return (
        <Link href="/rabota/[...slug]" as={link}>
            <a {...rest}>
                {category.title}
            </a>
        </Link>
    );
};

export default CategoryLink;