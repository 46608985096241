import React, {Fragment, useState} from "react";
import {Button, Header, Modal} from "semantic-ui-react";
import Tools from "../../../../tools/Tools";
import ym from 'react-yandex-metrika';

const VacancyContactButton = ({id, contact}) => {
    if(!contact || !contact.phone){
        return null;
    }
    const [open, setOpen] = useState(false);

    const name = [contact.firstName, contact.lastName].join(" ");
    const phone = contact.phone
        ? contact.phone.replace(/\D+/gm, "")
        : "";

    const viewContact = () => {
        try{
            ym('reachGoal', 'VIEW_CONTACT');
        }
        catch(e){
            console.log(e);
        }
        setOpen(true);
    };

    return (<Fragment>
        <Button id={id} className="button-blue contact-button" onClick={() => viewContact()}>
            Показать телефон
        </Button>
        <Modal size="tiny" open={open} onClose={() => setOpen(false)}>
            <Modal.Header>Контакты работодателя</Modal.Header>
            <Modal.Content>
                <Header as={"h3"}>
                    {Tools.isWhitespace(name) ? "Контактное лицо" : name}
                </Header>
                <Header as={"h2"}>
                    {
                        phone
                            ? <a href={"tel:+" + phone}>+{phone}</a>
                            : ""
                    }
                </Header>
                <Header as={"h3"}>
                    Скажите, что нашли вакансию на Avatarom
                </Header>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    positive
                    icon='checkmark'
                    labelPosition='right'
                    content='Хорошо!'
                    onClick={() => setOpen(false)}
                />
            </Modal.Actions>
        </Modal>
    </Fragment>);
};

export default VacancyContactButton;