import React, {useState} from "react";
import {Header, Icon} from "semantic-ui-react";
import Link from "next/link";

const VacancyDescription = ({id, description, collapsed = true, length = 350, link = true}) => {
    if(!description){
        return null;
    }
    const [collapse, setCollapse] = useState(collapsed);

    const content = collapse && description.length > length
        ? `${description.substr(0, length)}...`
        : description;

    const more = collapse && content.length < description.length
        ? <Header as={"a"} href="#" onClick={(e) => {
            e.preventDefault();
            setCollapse(false);
        }} className="secondary light vacancy-description-more">Подробнее <Icon name="caret down"/></Header>
        : null;

     const text =
         <p className="vacancy-description-content">
            {content}
        </p>;

    return link === true
        ? <div className="vacancy-description">
            <Link href="/vacancy/[id]" as={`/vacancy/${id}`}>
                <a>
                    {text}
                </a>
            </Link>
            {more}
        </div>

        : <div className="vacancy-description">
            {text}
            {more}
        </div>
}

export default VacancyDescription;