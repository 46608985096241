import ym from "react-yandex-metrika";

export default class AvataromMetrika {
    static reachGoal(goal){
        try{
            ym('reachGoal', goal);
        }
        catch (e) {
            console.error(e);
        }
    }
}