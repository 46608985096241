import React from "react";
import Tools from "../../tools/Tools";
import CategoryLink from "./CategoryLink";
import "./CategoryList.less";

const CategoryList = ({categories, region, showCount = true}) => {
    if(Tools.isEmpty(categories) || !region){
        return null;
    }
    return (
        <div className="category-list">
            {categories.map(category => {
                return (
                    <div key={category.id} className="category-item">
                        <CategoryLink showCount category={category} region={region} className={"category-item-link"} />
                    </div>
                );
            })}
        </div>
    );
};

export default CategoryList;