import React, {Fragment} from "react";
import PropTypes from "prop-types";
import VacancyItem from "../Item/VacancyItem";
import Pagination from "./Pagination";
import {Header, Grid} from "semantic-ui-react";
import "./VacancyList.less";
import VacancyRespondButton from "../Item/Components/VacancyRespondButton";
import LinkedProfessions from "../../Search/Navigation/LinkedProfessions";
import TopTags from "../../TopTags/TopTags";
import SearchAround from "../../Search/Around/SearchAround";
import Tools from "../../../tools/Tools";
import VacancyTitle from "../Item/Components/VacancyTitle";

const VacancyList = ({vacancies, navigation, region, tags, profession, usePagination = true, title = null, titleAs = "h2", count = null, url, showAround = false, pageTitle = null}) => {
    if(!vacancies || !vacancies.list){
        return null;
    }

    let items = vacancies.list;
    if(count){
        items = vacancies.list.slice(0, count);
    }

    const header = <div className="vacancy-list-header">
        <Header as={titleAs} className="primary light">
            {title}
        </Header>
    </div>;

    const subHeader = <Header as="h2" className="primary light vacancy-list-header sub-header">
        Найдено вакансий: {vacancies.total}
    </Header>

    const professionContent = profession && profession.title
        ? profession.title.toLowerCase()
        : null;

    const professionsContent = navigation && navigation.professions
        ? navigation.professions.slice(0, 6).map(p => p.name).join(", ")
        : null;

    const categoriesContent = navigation && navigation.links
        ? navigation.links.map(c => c.title.toLowerCase()).join(", ")
        : null;

    const vacanciesContent = vacancies.list
        ? vacancies.list.slice(0, 3).map(v => VacancyTitle.getTitle(v.professions) + " " + v.title).join(", ")
        : null;

    const textContent = pageTitle
        ? <Fragment>
            <Header as="h3" className="primary light">
                Ищите {Tools.toLowerCaseFirstChar(pageTitle).replace("работа", "работу")}?
            </Header>
            <p>
                Мы нашли {vacancies.total} свежих вакансий <b>{professionContent}</b> от прямых работодателей в городе {region.title}.
                {vacanciesContent ? ` Например: ${vacanciesContent}. `: ""}
            </p>
            <p>
                {professionsContent ? ` Также вам будут интересны вакансии ${professionsContent} и другие в городе ${region.title}.` : ""}
            </p>
            <p>
                {categoriesContent ? ` Работа для вас найдена в категориях: ${categoriesContent}. ` : ""}
            </p>
        </Fragment>
        : null;

    return (
        <div className="vacancy-list">
            <Grid centered className="no-margin-grid">
                <Grid.Column computer={11} tablet={16} mobile={16} className="vacancy-list-left">
                    {header}
                    <LinkedProfessions navigation={navigation} region={region} />
                    {subHeader}
                    { items.map(v => <VacancyItem key={v.id} vacancy={v} />) }
                    { usePagination === true ? <Pagination url={url} /> : null}
                </Grid.Column>
                <Grid.Column computer={5} tablet={16} mobile={16} className="vacancy-list-right">
                    <div className="sticky" style={{maxWidth: "400px"}}>
                        <Grid>
                            <Grid.Column computer={16} tablet={16} style={{padding: "5px 20px"}}>
                                {textContent}
                                <p>
                                    Актуальная база свежих вакансий обновляется ежедневно. Расскажите о себе и мы найдем подходящие для Вас вакансии.
                                </p>
                            </Grid.Column>
                            <Grid.Column computer={16} tablet={16}>
                                <VacancyRespondButton text={"Разместить резюме"} />
                            </Grid.Column>
                        </Grid>
                        <div>
                            {showAround ? <SearchAround /> : null}
                            <TopTags region={region} tags={tags} profession={profession} />
                        </div>
                    </div>
                </Grid.Column>
            </Grid>
        </div>
    );
};

VacancyList.propTypes = {
    vacancies: PropTypes.object,
    onMoreClick: PropTypes.func
};

export default VacancyList;