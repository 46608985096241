import React from "react";
import Link from "next/link";

const VacancyCompany = ({company}) => {
    if(!company){
        return null;
    }

    const link = `/company/${company.id}`;

    return (
        <div className="vacancy-company">
            <Link href={"/company/[id]"} as={link}>
                <a>
                    {company.title}
                </a>
            </Link>
        </div>
    );
};

export default VacancyCompany;