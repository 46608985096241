import React from "react";
import Tools from "../../../../tools/Tools";
import Link from "next/link";

const VacancyMetro = ({metro, region, profession}) => {
    if(Tools.isEmpty(metro) || !region){
        return null;
    }

    const stations = metro.map(s => {

        let link = `/rabota/${Tools.transliterate(region.name)}/metro/${Tools.transliterate(s.name)}`;
        if(profession){
            link += `/${Tools.transliterate(profession.name)}`;
        }

        return <Link key={s.id} href={"/rabota/[...slug]"} as={link} >
            <a style={{color: "unset !important"}}>
                <span className="metro-station">
                    <div className="metro-station-point" style={{backgroundColor: `#${s.color}`}}></div>
                    {s.name}
                </span>
            </a>
        </Link>
    });

    return (
        <div className="vacancy-metro">
            {stations}
        </div>
    );
};

export default VacancyMetro;