import React from "react";
import {Header} from "semantic-ui-react";
import Tools from "../../../../tools/Tools";

const Periods = {
    "month": "в месяц",
    "shift": "смена",
    "hour": "в час"
};

const VacancySalary = ({salary}) => {

    if(!salary){
        return null;
    }

    let from = "";
    let to = "";
    let period = "";

    if(salary.from){
        from = `от ${Tools.toCurrency(salary.from)}`;
    }
    if(salary.to){
        to = `до ${Tools.toCurrency(salary.to)}`;
    }
    if(salary.period){
        period = Periods[salary.period];
    }

    const content = `${from} ${to} ${period}`;

    return (
        <Header as={"h4"} className="secondary light" style={{fontWeight: "bold", fontSize: "1.4em"}}>{content}</Header>
    );
};

export default VacancySalary;