import {Header} from "semantic-ui-react";
import Tools from "../../../../tools/Tools";
import Link from "next/link";
import React from "react";

const VacancyTitle = ({professions, id, name, link = true, as = "h3"}) => {
    if(!professions){
        return ""
    }

    const professionTitle = VacancyTitle.getTitle(professions);
    const title = `${professionTitle} `;

    const content =
        <Header as={as} className="primary light" style={{fontSize: "1.6em"}}>
            {title}
            <span className="meta" style={{overflowWrap: "anywhere"}}>{name}</span>
        </Header>;

    return link === true
        ? <Link href="/vacancy/[id]" as={`/vacancy/${id}`}>
            <a>
                {content}
            </a>
        </Link>
        : content;
}

VacancyTitle.getTitle = (professions, toUpperCase = true) => {
    if(professions){
        return toUpperCase ? Tools.toUpperCaseFirstChar(professions[0].name) : professions[0].name
    }
    return "";
}

export default VacancyTitle;