import Tools from "../../../tools/Tools";
import ProfessionList from "../../Professions/ProfessionList";
import React from "react";

const LinkedProfessions = ({navigation, region}) => {
    if(!navigation || !region){
        return null;
    }

    let links = null;
    if(!Tools.isEmpty(navigation.professions)){
        links = <ProfessionList professions={navigation.professions}
                                region={region}
                                metro={navigation.metro}
                                tag={navigation.tag} />;
    }
    return (
        <nav>{links}</nav>
    );
};

export default LinkedProfessions;