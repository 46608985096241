import React, {useState} from "react";
import {Button, Dimmer, Grid, Header, Icon, Popup} from "semantic-ui-react";
import VacancyContactButton from "./VacancyContactButton";
import VacancyRespondButton from "./VacancyRespondButton";
import Tools from "../../../../tools/Tools";
import ym from "react-yandex-metrika";

const likeComments = [
    "И правда, отличная вакансия!",
    "Окей, будем показывать такое чаще.",
    "Все победы начинаются с победы над самим собой.  © Леонид Леонов",
    "Условия труда впечатляют...",
    "Решено! Меняю работу!",
    "Мне обязательно повезёт!",
    "Уйду из Аватарома работать сюда",
    "Мы – хозяева своей судьбы. Мы – капитаны своих душ. © Уинстон Черчиль",
    "Вакансия мечты, не иначе!",
    "А детстве я хотел стать космонавтом....",
    "Following a Star!",
    "Следуй за мечтой!",
    "Путешествие в тысячу миль начинается с первого шага. © Лао-цзы",
    "Я этого хочу. Значит, это будет. © Генри Форд",
];

const VacancyActions = ({id, contact, isFavorite = false, status}) => {

    const [favorite, setFavorite] = useState(isFavorite);
    const [active, setActive] = useState(false);

    const copyToClipboard = async () => {
        if(navigator && navigator.clipboard){
            try{
                await navigator.clipboard.writeText(`https://avatarom.com/vacancy/${id}`);
                ym('reachGoal', 'SHARE_VACANCY', {vacancyId: id});
            }
            catch (e) {
                console.log(e);
            }
        }
    };

    let dimmer = null;
    const like = () => {
        if(!favorite){
            try{
                ym('reachGoal', 'LIKE_VACANCY', {vacancyId: id});
            }
            catch (e) {
                console.log(e);
            }
        }
        setFavorite(!favorite);
        if(!favorite){
            setActive(true);
            if(dimmer === null){
                dimmer = setTimeout(() => {
                    setActive(false);
                    dimmer = null;
                }, 1500);
            }
        }
    };

    const closeDimmer = () => {
        if(dimmer){
            clearTimeout(dimmer);
            dimmer = null;
        }
        setActive(false);
    };

    return (
        <Grid className="vacancy-actions">
            <Grid.Column computer={4} tablet={4} mobile={16} style={{paddingBottom: "0"}}>
                <VacancyRespondButton />
            </Grid.Column>
            <Grid.Column computer={5} tablet={5} mobile={10}>
                {status != 'deleted' ? <VacancyContactButton contact={contact} />: null}
            </Grid.Column>
            <Grid.Column computer={7} tablet={7} mobile={6} className="favorite-action">
                <Popup content="Ссылка скопирована в буфер!"
                       inverted
                       on={"click"}
                       trigger={<Button basic icon={"bullhorn"} onClick={copyToClipboard} />} />

                <Button icon={"heart"}
                        basic
                        className={favorite ? "favorite" : ""}
                        onClick={like} />

            </Grid.Column>
            <Dimmer active={active} onClickOutside={closeDimmer} page className={"message-dimmer"}>
                <Header as='h2' icon inverted>
                    <Icon name='heart' color={"red"} />
                    {likeComments[Tools.getRandomInt(0, likeComments.length - 1)]}
                </Header>
            </Dimmer>
        </Grid>
    );
};

export default VacancyActions;